import { render, staticRenderFns } from "./BarChartContainer.vue?vue&type=template&id=a54d4b78&scoped=true&"
import script from "./BarChartContainer.vue?vue&type=script&lang=js&"
export * from "./BarChartContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a54d4b78",
  null
  
)

export default component.exports