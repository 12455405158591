<template>
  <v-row>
    <v-col md="12">
      <v-hover v-slot:default="{ hover }">
        <v-card :elevation="hover ? 5 : 2" class="mx-auto">
          <v-card-text>
            <slot/>
          </v-card-text>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "BarChartContainer"
}
</script>

<style scoped>

</style>