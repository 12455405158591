<template>
  <bar-chart-container>
    <apexchart type="bar" height="350" width="100%" :options="chartOptions" :series="series"/>
  </bar-chart-container>
</template>

<script>
import BarChartContainer from "./BarChartContainer";
import moment from "moment";
import utility from "../../helper/utility";
export default {
  name: "InvoiceChart",
  components: {BarChartContainer},
  props: {
    selectedDate: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    selectedDate: {
      immediate: true,
      handler(value) {
        this.setChartDates();
      }
    }
  },
  data() {
    return {
      xaxisLabels: [],
      monthOfYear: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      chartSeries: []
    }
  },
  mounted() {
    this.setChartDates();
  },
  computed: {
    series() {
      return this.chartSeries;
    },
    chartOptions() {
      const me = this;
      return {
        chart: {
          redrawOnParentResize: true,
          type: 'bar',
          height: 350,
          width: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: me.xaxisLabels,
        },
        yaxis: {
          title: {
            text: 'Invoice Summary Report'
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return "$" + utility.abbreviateNumber(val)
            }
          }
        }
      }
    }
  },
  methods: {
    setChartDates() {
      const {startDate, endDate, groupBy} = this.selectedDate;
      if (groupBy === 'm') {
        this.xaxisLabels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      } else {
        const _endDate = moment(endDate).add(1, 'days');
        let dt = startDate;
        const momentDate = moment(dt);

        this.xaxisLabels = [];

        while (!momentDate.isSame(_endDate, 'day')) {
          this.xaxisLabels.push(momentDate.format('DD'));
          dt = momentDate.add(1, "days");
        }
      }

      this.onHandleChart();
    },
    onHandleChart() {
      this.chartSeries = [];
      this.$store.commit('SET_DYNAMIC_WIDGETS', []);
      this.$store.dispatch('loadInvoiceByDate', this.selectedDate)
          .then(({data}) => {
            const {createdInvoice, paidInvoice, unpaidInvoice, cInvoiceInfo, pInvoiceInfo, orderInfo, creditInfo} = data;

            this.$store.commit('SET_DYNAMIC_WIDGETS', [
              {
                type: 'totalCreated',
                total: cInvoiceInfo[0].totalInvoice ? cInvoiceInfo[0].totalInvoice : 0,
                amount: cInvoiceInfo[0].total ? cInvoiceInfo[0].total : 0
              },
              {
                type: 'totalPaid',
                total: pInvoiceInfo[0].totalInvoice ? pInvoiceInfo[0].totalInvoice : 0,
                amount: pInvoiceInfo[0].total ? pInvoiceInfo[0].total : 0
              },
              {
                type: 'totalOrder',
                total: orderInfo[0].totalOrder ? orderInfo[0].totalOrder : 0,
                amount: orderInfo[0].total ? orderInfo[0].total : 0
              },
              {
                type: 'totalCredit',
                total: creditInfo[0].totalCredit ? creditInfo[0].totalCredit : 0,
                amount: creditInfo[0].total ? creditInfo[0].total : 0
              },
            ]);
            let _netSales = [];
            let _netPaid = [];
            let _netUnpaid = [];
            let labels = 'xaxisLabels';

            if(this.selectedDate.groupBy === 'm') {
              labels = 'monthOfYear'
            }

            this[labels].forEach((day) => {
              const netSale = createdInvoice.find((item) => item.day === day);
              const netPaid = paidInvoice.find((item) => item.day === day);
              const netUnpaid = unpaidInvoice.find((item) => item.day === day);

              if(netSale) {
                _netSales.push(Number.parseFloat(netSale.total).toFixed(2));
              }else{
                _netSales.push(0.00);
              }

              if(netPaid) {
                _netPaid.push(Number.parseFloat(netPaid.total).toFixed(2));
              }else{
                _netPaid.push(0.00);
              }

              if(netUnpaid) {
                _netUnpaid.push(Number.parseFloat(netUnpaid.total).toFixed(2));
              }else{
                _netUnpaid.push(0.00);
              }
            });

            this.chartSeries = [
              {
                name: 'Net Total',
                data: _netSales
              }
              ,
              {
                name: 'Paid',
                data: _netPaid
              },
              {
                name: 'Unpaid',
                data: _netUnpaid
              }
            ];
          })
      .catch((error) => console.log(error))
    }
  }
}
</script>

<style scoped>

</style>